<template lang="pug">
div
  //- van-cell(title='显示价格' center)
    template(#default)
      van-switch(v-model="show" size="24px" @change='onShowPrice')
  van-cell(title='更改密码' is-link :to="{name: 'resetpassword'}")
  van-cell(title='切换公司' :value="companyInfo.com_name" is-link @click="showPicker = true")
  div(style='margin: 16px;')
    van-button(round block type='danger' @click="logout")
      | 退出登录
  van-popup(v-model="showPicker" round position="bottom")
    van-picker(title='选择公司', show-toolbar='', :columns='columns', @confirm='onConfirm', @cancel='onCancel', value-key='name')

</template>

<script>
import {Switch} from 'vant'
import { RemoveAll, GetToken } from '@/lib/helpers/Token'
import ENV from '../../../local_env.json'
import store from '@/stores'

export default {
  components: {
    [Switch.name]: Switch
  },
  data() {
    return {
      show: false,
      showPicker: false,
      companyName: '嘉利信得',
      columns: [
        {
          comId: "96",
          route: "jlxd",
          name: '北京嘉利信得'
        },
         {
          comId: "78",
          route: "dpjylm",
          name: '搭配家云联盟'
        }
      ],
      sellers:[],   // 当前用户下的销售员列表 
    }
  },
  computed: {
    companyInfo () {
      return this.$store.state.companyInfo
    },
    userId() {
      return this.$store.state.userInfo.user_poi_users
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    }
  },
  methods: {
    async init() {
      await this.getCompanysSkin()
    },
    onShowPrice(show) {
      // SetShowPrice(show)
    },
    logout() {
      // 
      // this.$store.dispatch('logout')
      // this.$router.push({
      //   name: 'home'
      // })
      // this.$toast('成功退出')
      //  点击退出，首先跳转passport清理passport端token信息
      const origin = this.$router.resolve({
        name: 'home'
      })
      let originUrl = `//${window.location.host}${origin.href}`
      let url = `${ENV.passport_url}?com_id=${store.state.companyInfo.id}&app_id=${ENV.id}&redirectUrl=${originUrl}&type=logout`
      RemoveAll()
      window.location.href = url
    },

    async onConfirm(value) {
      if(this.companyInfo.id == value.comId) { 
        this.showPicker = false
        return
      }
      let route = {
        params: {
          company_route: value.route
        },
        name: 'person'
      } 
      this.showPicker = false
      this.$router.replace(route)
      let currentSeller = this.sellers.find(p=> p.com_id_poi_companys == value.comId)
      this.$store.commit('setSellerInfo', currentSeller)
      
      await this.$api.PutTokenComId({token: GetToken(),com_id:value.comId})
      // 要重新获取销售信息和公司信息
      this.$toast('切换成功')
      window.location.reload()
    },
    
    // 获取当前销售员所属公司列表
    async getCompanysSkin() {
      this.columns = []
      try {
        let res = await this.$api.GetSellerService()
        this.columns = res.data.map((item) => {
          return {
            comId: item.id,
            route: item.route_abb,
            name: item.com_name
          }
        })
      } catch(ex) {
        this.$toast(this.$error(ex))   
        this.columns = []     
      }
    },
 
    onCancel() {
      this.showPicker = false
    },
  },
  async activated() {
    this.$emit('on-nav', {
      leftText: '返回',
      leftArrow: true,
      onClickLeft: () => {
        this.$router.push('person')
      }
    }),
    this.init()
  }
}
</script>